import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ColorChooser,
  ImageLoader,
  MessageDisplay,
} from "../../../components/common";
import {
  ARTICLES,
  RECOMMENDED_PRODUCTS,
  SHOP,
} from "../../../constants/routes";
import { useBasket, useDocumentTitle, useScrollTop } from "../../../hooks";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { Context } from "../../../Context";

const Article = ({ propArticle }) => {
  const { id } = useParams();

  useScrollTop();

  const { articles } = useContext(Context);
  const article = useMemo(() => {
    if (propArticle) {
      return propArticle;
    }
    return articles?.filter?.((i) => i.id === id)?.[0];
  }, [articles, id]);
  useDocumentTitle(`View ${article?.name || "Article"}`);

  const [selectedContent, setSelectedContent] = useState(
    article?.content?.[0] || ""
  );
  const date = new Date(article?.timeStamp);
  useEffect(() => {
    setSelectedContent(article?.contents[0]);
  }, [article]);

  return (
    <main
      className="content"
      style={{
        paddingBottom: "0px",
        position: "relative",
        marginBottom: "0px",
        minHeight: "10vh",
      }}
    >
      {!article && (
        <div className="loader">
          <h4>Loading Article...</h4>
          <br />
          <LoadingOutlined style={{ fontSize: "3rem" }} />
        </div>
      )}
      {article && (
        <div className="product-view">
          {propArticle ? (
            <></>
          ) : (
            <Link to={ARTICLES}>
              <h3 className="button-link d-inline-flex">
                <ArrowLeftOutlined />
                &nbsp; Back to catalog
              </h3>
            </Link>
          )}
          <div className="product-modal">
            {article.contents.length !== 0 && (
              <div className="product-modal-image-collection">
                {article.contents.map((content) => (
                  <div
                    className="product-modal-image-collection-wrapper"
                    key={content.id}
                    onClick={() => setSelectedContent(content)}
                    role="presentation"
                  >
                    {content?.type === "image" && (
                      <ImageLoader
                        className="product-modal-image-collection-img"
                        src={content.url}
                      />
                    )}
                    {content?.type === "video" && (
                      <video
                        // controls
                        className="product-modal-image-collection-img"
                        src={content.url}
                      />
                    )}
                    {!content?.type && <></>}
                  </div>
                ))}
              </div>
            )}
            <div className="product-modal-image-wrapper">
              {selectedContent?.type === "image" && (
                <ImageLoader
                  alt={article?.name}
                  className="product-modal-image"
                  src={selectedContent?.url}
                />
              )}{" "}
              {selectedContent?.type === "video" && (
                <video
                  controls
                  className="product-modal-image-collection-img"
                  src={selectedContent?.url}
                />
              )}
            </div>
            <div className="product-modal-details">
              <br />
              <span className="text-subtle">
                {date.toUTCString().split("GMT")[0]}
              </span>
              <h1 className="margin-top-0">{article?.name}</h1>
              <span>{article?.text}</span>
              <br />
              <br />
              <div className="divider" />
              <br />
              <br />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default Article;
