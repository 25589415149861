import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OrderItem from "../account/components/orders/OrderItem";
import { Box, Paper } from "@mui/material";

// Just add this feature if you want :P

const AdminOrders = () => {
  const functions = getFunctions();
  const getAllOrders = httpsCallable(functions, "getAllOrders");

  const dispatch = useDispatch();

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await getAllOrders()
        .then((response) => {
          console.log(response);
          setOrders(response?.data?.sort((a, b) => a.timeStamp - b.timeStamp));
        })
        .catch((error) => console.log(error));
      return data;
    })();
  }, []);

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  return (
    <div
      className="loader"
      style={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        margin: "0 auto",
      }}
    >
      <strong>
        {orders?.length > 0 ? (
          orders?.map((order) => (
            <>
              <Box>
                <Paper
                  sx={{
                    display: "flex",
                    width: "80vw",
                    marginTop: "1rem",
                    position: "relative",
                  }}
                >
                  <div
                    className="item"
                    style={{
                      paddingTop: "2rem",
                      margin: "0 auto",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "30vw",
                        margin: "0 auto",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ position: "relative", bottom: ".5rem" }}>
                          Price:
                        </p>
                        <b>${(order.amount / 100).toFixed(2)}</b>
                      </span>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ position: "relative", bottom: ".5rem" }} p>
                          Date:
                        </p>
                        <b>{Date(order.timeStamp).split("GMT")[0]}</b>
                      </span>
                    </div>
                    {/* <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p>Status:</p>
                      <b>{order.shipped ? "Shipped" : "Not Yet Shipped"}</b>
                    </span> */}

                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {order.basket.map((item, i) => {
                        return (
                          <Link to={`/product/${item.id}`}>
                            <div style={{ paddingTop: "2rem" }}>
                              <OrderItem
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${item.id}_${i}`}
                                product={item}
                                basket={order.basket}
                                dispatch={dispatch}
                              />
                            </div>
                          </Link>
                        );
                      })}
                    </span>
                  </div>
                </Paper>
              </Box>
            </>
          ))
        ) : (
          <span className="text-subtle">
            You don&apos;t have any orders yet
          </span>
        )}
      </strong>
    </div>
  );
};

export default AdminOrders;
