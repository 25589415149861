/* eslint-disable react/forbid-prop-types */
import { Boundary, MessageDisplay } from "../../../components/common";
import PropType from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ArticleList = (props) => {
  const { articles, children } = props;
  const [isFetching, setFetching] = useState(false);
  const dispatch = useDispatch();

  // const fetchArticles = () => {
  //   setFetching(true);
  //   dispatch(getArticles(articles.lastRefKey));
  // };

  // useEffect(() => {
  //   if (articles.items.length === 0 || !articles.lastRefKey) {
  //     fetchArticles();
  //   }

  //   window.scrollTo(0, 0);
  //   return () => dispatch(setLoading(false));
  // }, []);

  // useEffect(() => {
  //   setFetching(false);
  // }, [articles.lastRefKey]);

  return <Boundary>{children}</Boundary>;
};

ArticleList.defaultProps = {
  requestStatus: null,
};

ArticleList.propTypes = {
  articles: PropType.object.isRequired,
  children: PropType.oneOfType([PropType.arrayOf(PropType.node), PropType.node])
    .isRequired,
};

export default ArticleList;
