/* eslint-disable react/forbid-prop-types */
import { Boundary } from "../../../../components/common";
import PropType from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ArticleList = (props) => {
  const { children } = props;

  return <Boundary>{children}</Boundary>;
};

ArticleList.defaultProps = {
  requestStatus: null,
};

ArticleList.propTypes = {
  articles: PropType.object.isRequired,
  children: PropType.oneOfType([PropType.arrayOf(PropType.node), PropType.node])
    .isRequired,
};

export default ArticleList;
