import { CloseOutlined } from "@ant-design/icons";
import { ImageLoader } from "../../../../components/common";
import { displayMoney } from "../../../../helpers/utils";
import PropType from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeFromBasket } from "../../../../redux/actions/basketActions";
import { getProducts } from "../../../../redux/actions/productActions";

const OrderItem = ({ product, dispatch }) => {
  const onRemoveFromBasket = () => dispatch(removeFromBasket(product.id));

  const items = useSelector((state) => ({
    products: state.products.items,
  }));

  const item = useMemo(() => {
    return items?.products?.filter?.((i) => i.id === product.id)?.[0];
  }, [items]);

  return (
    <div className="basket-item">
      <div className="basket-item-wrapper">
        <div className="basket-item-img-wrapper">
          <ImageLoader
            alt={product.name}
            className="basket-item-img"
            src={item?.image}
          />
        </div>
        <div className="basket-item-details">
          <Link
            to={`/product/${product.id}`}
            onClick={() => document.body.classList.remove("is-basket-open")}
          >
            <h4 className="underline" style={{ whiteSpace: "nowrap" }}>
              {product.name}
            </h4>
          </Link>
          <div className="basket-item-specs">
            <div>
              <span className="spec-title">Size</span>
              <h5 className="my-0">{product.size}</h5>
            </div>
            <div>
              <span className="spec-title">Color</span>
              <div
                style={{
                  backgroundColor:
                    product?.color ||
                    product.selectedColor ||
                    product.availableColors?.[0].hex,
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                }}
              />
            </div>
          </div>
        </div>
        <div className="basket-item-price">
          <h4 className="my-0">
            {item?.price ? displayMoney(item?.price) : ""}
          </h4>
        </div>
      </div>
    </div>
  );
};

OrderItem.propTypes = {
  product: PropType.shape({
    id: PropType.string,
    name: PropType.string,
    brand: PropType.string,
    price: PropType.number,
    quantity: PropType.number,
    maxQuantity: PropType.number,
    description: PropType.string,
    keywords: PropType.arrayOf(PropType.string),
    selectedSize: PropType.string,
    selectedColor: PropType.string,
    imageCollection: PropType.arrayOf(PropType.string),
    sizes: PropType.arrayOf(PropType.number),
    image: PropType.string,
    imageUrl: PropType.string,
    isFeatured: PropType.bool,
    isRecommended: PropType.bool,
    availableColors: PropType.arrayOf(PropType.string),
  }).isRequired,
};

export default OrderItem;
