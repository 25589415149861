/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { ADMIN_DASHBOARD, SIGNIN } from "../constants/routes";
import PropType from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Navigate, Route, useLocation } from "react-router-dom";

const PrivateRoute = ({ isAuth, role, component: Component, props }) => {
  const location = useLocation();
  if (isAuth && (role === "USER" || role === "ADMIN")) {
    return (
      <main className="content">
        <Component {...props} />
      </main>
    );
  }

  if (isAuth && role === "ADMIN") {
    return <Navigate to={ADMIN_DASHBOARD} />;
  }

  return (
    <Navigate
      to={{
        pathname: SIGNIN,
        // eslint-disable-next-line react/prop-types
        state: { from: location.pathname },
      }}
    />
  );
};

PrivateRoute.defaultProps = {
  isAuth: false,
  role: "USER",
};

PrivateRoute.propTypes = {
  isAuth: PropType.bool,
  role: PropType.string,
  component: PropType.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  rest: PropType.any,
};

const mapStateToProps = ({ auth }) => ({
  isAuth: !!auth,
  role: auth?.role || "",
});

export default connect(mapStateToProps)(PrivateRoute);
