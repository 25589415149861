import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState, useEffect, ReactElement, ReactNode } from "react";
// import Images from "./images";
const Context = React.createContext({
  cost: 0,
});

function ContextProvider({ children }) {
  const functions = getFunctions();
  const [cost, setCost] = useState(0);

  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [shipping, setShipping] = useState({});

  const [articles, setArticles] = useState();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const getArticles = async () => {
    const allArticles = await httpsCallable(functions, "getArticles")()
      .then((response) => {
        setArticles(response?.data?.sort((a, b) => a.timeStamp - b.timeStamp));
        return response;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
    console.log("allArticles: ", allArticles);
  };

  useEffect(() => {
    console.log(articles);
  }, [articles]);

  ////
  return (
    <Context.Provider
      value={{
        cost,
        setCost,

        shipping,
        setShipping,

        paymentProcessing,
        setPaymentProcessing,

        articles,
        setArticles,
        getArticles,

        isMobileNavOpen,
        setMobileNavOpen,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { ContextProvider, Context };
