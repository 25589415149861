import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ColorChooser,
  ImageLoader,
  MessageDisplay,
} from "../../../components/common";
import {
  ADMIN_ARTICLES,
  ARTICLES,
  RECOMMENDED_PRODUCTS,
  SHOP,
} from "../../../constants/routes";
import { useDocumentTitle, useScrollTop } from "../../../hooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../Context";
import { Button, Input, Paper, TextField } from "@mui/material";
import { displayActionMessage } from "../../../helpers/utils";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const AddArticle = () => {
  const { id } = useParams();

  useScrollTop();

  const { articles, getArticles } = useContext(Context);
  const functions = getFunctions();
  const storage = getStorage();

  const navigate = useNavigate();
  const article = useMemo(() => {
    return articles?.filter?.((i) => i.id === id)?.[0];
  }, [articles, id]);
  useDocumentTitle(`View ${article?.name || "Article"}`);

  const [selectedContent, setSelectedContent] = useState(
    article?.content?.[0] || ""
  );

  useEffect(() => {
    setSelectedContent(article?.contents[0]);
  }, [article]);

  const date = new Date(article?.timeStamp);

  const [uploads, setUploads] = useState([0]);
  const [filledUploads, setFilledUploads] = useState([]);

  const handleFiles = (files) => {
    console.log(files);
    const newUploads = [...filledUploads];
    for (let file of files) {
      if (!filledUploads.includes(file)) {
        newUploads.push(file);
      }
      setFilledUploads(newUploads);
    }
  };

  const clearAll = () => {
    setUploads([0]);
    setFilledUploads();
  };

  // const [completedUploads, setCompletedUploads] = useState([]);

  const handlePublish = async (e) => {
    e.preventDefault();
    const publish = httpsCallable(functions, "createArticles");
    if (text === "") {
      displayActionMessage("You need to write your article first", "info");
    } else if (title == "") {
      displayActionMessage("You need to add a title", "info");
    } else {
      displayActionMessage("Publishing article...", "success");

      const completedUploads = [];

      filledUploads.forEach((file) => {
        const storageRef = ref(
          storage,
          `${file.type.includes("image") ? "image" : "video"}/${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);

        let counter = 0;
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          async () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            await getDownloadURL(uploadTask.snapshot.ref).then(
              (downloadURL) => {
                console.log("File available at", downloadURL);
                completedUploads.push({
                  type: file.type.includes("image") ? "image" : "video",
                  url: downloadURL,
                });
              }
            );
            ++counter;
            if (counter === filledUploads.length) {
              httpsCallable(
                functions,
                "createArticles"
              )({ contents: completedUploads, text, title })
                .then((response) => {
                  navigate(ADMIN_ARTICLES);
                  getArticles();
                  displayActionMessage(
                    "Your article has been published successfully!",
                    "success"
                  );
                })
                .catch((error) => {
                  displayActionMessage(
                    `There was an error publishing your article: ${error}`,
                    "warning"
                  );
                });
            }
          }
        );
      });
    }
  };

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  return (
    <main className="content">
      <section
        className="article-list-wrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Button
          variant="contained"
          onClick={(e) => handlePublish(e)}
          style={{
            fontSize: "large",
            margin: "0",
            position: "relative",
            left: "3.5rem",
          }}
        >
          Publish Article
        </Button>
        <Input
          style={{
            backgroundColor: "white",
            marginTop: "3rem",
            position: "relative",
            left: "3.5rem",
            fontSize: "x-large",
            fontWeight: "bolder",
          }}
          placeholder="Title"
          title="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          multiline
          value={text}
          placeholder="（よろしくお願いします）!"
          onChange={(e) => setText(e.target.value)}
          style={{
            fontSize: "large",
            margin: "0",
            marginTop: "3rem",
            position: "relative",
            left: "3.5rem",
            border: "none",
            backgroundColor: "white",
            minHeight: "40vh",
            minWidth: "40vw",
          }}
        />
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginTop: "1rem",
          }}
        >
          <Button
            onClick={() => setUploads([...uploads, uploads.length])}
            variant="contained"
            disabled={filledUploads.length !== uploads.length}
            style={{
              fontSize: "large",
              margin: "0",
              position: "relative",
              left: "3.5rem",
            }}
          >
            Upload More
          </Button>

          <Button
            onClick={() => clearAll()}
            variant="contained"
            disabled={filledUploads.length === 0}
            style={{
              fontSize: "large",
              margin: "0",
              position: "relative",
              left: "3.5rem",
            }}
          >
            Clear All
          </Button>
        </span>
        {uploads.map((idx, i) => {
          return (
            <Input
              style={{ height: "5rem", left: "3.5rem" }}
              id={`input-${i}`}
              type="file"
              onChange={(e) => handleFiles(e.target.files)}
            />
          );
        })}
      </section>
    </main>
  );
};

export default AddArticle;
