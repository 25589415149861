import { ArrowRightOutlined } from "@ant-design/icons";
import { MessageDisplay } from "../../components/common";
import { ProductShowcaseGrid } from "../../components/product";
import {
  FEATURED_PRODUCTS,
  RECOMMENDED_PRODUCTS,
  SHOP,
} from "../../constants/routes";
import {
  useDocumentTitle,
  useFeaturedProducts,
  useRecommendedProducts,
  useScrollTop,
} from "../../hooks";
import bannerImg from "../../images/defaultBanner.jpg";
import React from "react";
import { Link } from "react-router-dom";
import "./home.scss";

const Home = () => {
  useDocumentTitle("Jiva's Originals | Home");
  useScrollTop();

  const {
    featuredProducts,
    fetchFeaturedProducts,
    isLoading: isLoadingFeatured,
    error: errorFeatured,
  } = useFeaturedProducts(6);
  const {
    recommendedProducts,
    fetchRecommendedProducts,
    isLoading: isLoadingRecommended,
    error: errorRecommended,
  } = useRecommendedProducts(6);

  return (
    <main className="content">
      <div className="home">
        <div className="banner">
          <div className="banner-desc">
            <h1
              className="text-thin"
              style={{ whiteSpace: "normal", maxWidth: "70vw" }}
            >
              <strong style={{ whiteSpace: "normal", maxWidth: "30vw" }}>
                Bring out
              </strong>
              &nbsp;your&nbsp;
              <br className="only-portrait" />
              <strong style={{ whiteSpace: "normal", maxWidth: "30vw" }}>
                Inner Goddess...
              </strong>
            </h1>
            <p style={{ lineHeight: "3.5rem" }}>
            Discover the enchantment of bellydance with our captivating collection of clothing at Jiva's Originals. Step into a world of timeless beauty and grace as you unleash your inner goddess. Our meticulously crafted garments are designed to enhance your every move, ensuring you radiate confidence and allure. Whether you're a seasoned performer or a novice adventurer, our exquisite designs embrace the spirit of renaissance festivals, transporting you to a realm where fantasy becomes reality!  </p>
            <br />
            <Link to={SHOP} className="button">
              Shop Now &nbsp;
              <ArrowRightOutlined />
            </Link>
          </div>
          <div className="banner-img">
            <img src={bannerImg} alt="" />
          </div>
        </div>
        <div className="display">
          <div className="display-header">
            <h1>Featured Products</h1>
            <Link to={FEATURED_PRODUCTS}>See All</Link>
          </div>
          {errorFeatured && !isLoadingFeatured ? (
            <MessageDisplay
              message={errorFeatured}
              action={fetchFeaturedProducts}
              buttonLabel="Try Again"
            />
          ) : (
            <ProductShowcaseGrid
              products={featuredProducts}
              skeletonCount={6}
            />
          )}
        </div>
        <div className="display">
          <div className="display-header">
            <h1>Recommended Products</h1>
            <Link to={RECOMMENDED_PRODUCTS}>See All</Link>
          </div>
          {errorRecommended && !isLoadingRecommended ? (
            <MessageDisplay
              message={errorRecommended}
              action={fetchRecommendedProducts}
              buttonLabel="Try Again"
            />
          ) : (
            <ProductShowcaseGrid
              products={recommendedProducts}
              skeletonCount={6}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default Home;
