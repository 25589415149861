/* eslint-disable indent */
import { FilterOutlined, ShoppingOutlined } from "@ant-design/icons";
import * as ROUTE from "../../constants/routes";
import logo from "../../images/logo.jpg";
import menu from "../../images/menu.png";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import UserAvatar from "../../views/account/components/UserAvatar";
import BasketToggle from "../basket/BasketToggle";
import MenuToggle from "../menu/MenuToggle";
import Badge from "./Badge";
import FiltersToggle from "./FiltersToggle";
import MobileNavigation from "./MobileNavigation";
import SearchBar from "./SearchBar";
import "./styles/Navigation.scss";

const Navigation = () => {
  const navbar = useRef(null);
  const { pathname } = useLocation();

  const store = useSelector((state) => ({
    basketLength: state.basket.length,
    user: state.auth,
    isAuthenticating: state.app.isAuthenticating,
    isLoading: state.app.loading,
  }));

  const scrollHandler = () => {
    if (navbar.current && window.screen.width > 480) {
      if (window.pageYOffset >= 70) {
        navbar.current.classList.add("is-nav-scrolled");
      } else {
        navbar.current.classList.remove("is-nav-scrolled");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  // const onClickLink = (e) => {
  //   if (store.isAuthenticating) e.preventDefault();
  // };

  // disable the basket toggle to these pathnames
  const basketDisabledpathnames = [
    ROUTE.CHECKOUT_STEP_1,
    ROUTE.CHECKOUT_STEP_2,
    ROUTE.CHECKOUT_STEP_3,
    ROUTE.SIGNIN,
    ROUTE.SIGNUP,
    ROUTE.FORGOT_PASSWORD,
  ];

  if (
    store.user &&
    store.user.role === "ADMIN" &&
    pathname.includes("/admin")
  ) {
    return null;
  }
  // if (window.screen.width <= 800) {
  //   return (
  //     <MobileNavigation
  //       // eslint-disable-next-line react/jsx-props-no-spreading
  //       {...store}
  //       disabledPaths={basketDisabledpathnames}
  //       pathname={pathname}
  //     />
  //   );
  // }
  return (
    <nav className="navigation" ref={navbar}>
      <li className="navigation-menu-item">
        <BasketToggle>
          {({ onMenuToggle }) => (
            <button
              className="button-link navigation-menu-link basket-toggle basket-portrait"
              disabled={basketDisabledpathnames.includes(pathname)}
              onClick={onMenuToggle}
              type="button"
            >
              <img alt="Menu" src={menu} className="menu-img" />
            </button>
          )}
        </BasketToggle>
      </li>
      <div className="logo">
        <Link
          // onClick
          to="/"
        >
          <img alt="Logo" src={logo} className="logo-img" />
        </Link>
      </div>
      <span className="nav-items">
        <ul
          className="navigation-menu-main menu"
          // hacking with the non-portrait class
        >
          <li>
            <NavLink
              activeClassName="navigation-menu-active"
              exact
              to={ROUTE.HOME}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="navigation-menu-active" to={ROUTE.SHOP}>
              Shop
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="navigation-menu-active"
              to={ROUTE.FEATURED_PRODUCTS}
            >
              Featured
            </NavLink>
          </li>
          <li className="nonportrait">
            <NavLink
              activeClassName="navigation-menu-active"
              to={ROUTE.RECOMMENDED_PRODUCTS}
            >
              Recommended
            </NavLink>
          </li>
          {store?.user?.role === "ADMIN" && (
            <li>
              <NavLink
                activeClassName="navigation-menu-active"
                to={ROUTE.ADMIN_DASHBOARD}
              >
                Admin
              </NavLink>
            </li>
          )}
        </ul>
      </span>
      <span
        style={{
          float: "left",
          flexDirection: "row",
          display: "flex",
          marginLeft: "-5rem",
          maxWidth: "30vw",
        }}
      >
        {(pathname === ROUTE.SHOP || pathname === ROUTE.SEARCH) && (
          <FiltersToggle className="nonportrait">
            <button
              className="button-muted button-small nonportrait"
              type="button"
            >
              <FilterOutlined />
            </button>
          </FiltersToggle>
        )}
        <SearchBar style={{ flex: "3" }} />
      </span>
      <ul className="navigation-menu">
        <li className="navigation-menu-item">
          <BasketToggle>
            {({ onClickToggle }) => (
              <button
                className="button-link navigation-menu-link basket-toggle basket-portrait"
                disabled={basketDisabledpathnames.includes(pathname)}
                onClick={onClickToggle}
                type="button"
              >
                <Badge count={store.basketLength}>
                  <ShoppingOutlined style={{ fontSize: "2.4rem" }} />
                </Badge>
              </button>
            )}
          </BasketToggle>
        </li>
        {store.user ? (
          <li className="navigation-menu-item">
            <UserAvatar />
          </li>
        ) : (
          <li className="navigation-action">
            {pathname !== ROUTE.SIGNUP && (
              <Link
                className="button button-small"
                // onClick={onClickLink}
                to={ROUTE.SIGNUP}
                style={{ whiteSpace: "nowrap" }}
              >
                Sign Up
              </Link>
            )}
            {pathname !== ROUTE.SIGNIN && (
              <Link
                className="button button-small button-muted margin-left-s"
                // onClick={onClickLink}
                to={ROUTE.SIGNIN}
                style={{ whiteSpace: "nowrap" }}
              >
                Sign In
              </Link>
            )}
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
