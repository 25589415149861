const firebaseConfig = {
  apiKey: "AIzaSyCXlSjd9FzEcwRgr777It8EuS_X8Y-tYwM",
  authDomain: "jiva-co.firebaseapp.com",
  projectId: "jiva-co",
  storageBucket: "jiva-co.appspot.com",
  messagingSenderId: "916359380723",
  appId: "1:916359380723:web:76efff073459bdc8374186",
  measurementId: "G-XQLM2GMD82",
};
export default firebaseConfig;
// const firebaseConfig = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DB_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MSG_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
// };

// export default firebaseConfig;
