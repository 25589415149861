/* eslint-disable react/jsx-props-no-spreading */
import ArticleList from "./ArticleList";
import ArticleGrid from "./ArticleGrid";
import { useDocumentTitle, useScrollTop } from "../../../hooks";
import React, { useContext, useEffect } from "react";
import { Context } from "../../../Context";
import ArticleItem from "./ArticleItem";
import Article from "../view";

const Articles = () => {
  useDocumentTitle("Articles | Jiva's Originals");
  useScrollTop();

  const { articles, getArticles } = useContext(Context);

  useEffect(() => {
    if (!articles) {
      getArticles();
    }
  }, [articles]);

  return (
    <main className="content">
      <section className="product-list-wrapper">
        {/* <ArticleList {...articles}> */}
        {/* <ArticleGrid articles={articles} /> */}
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {articles ? (
            articles?.map((article) => {
              return <Article propArticle={article} />;
            })
          ) : (
            <>
              <p>No articles yet</p>
            </>
          )}
        </span>
        {/* </ArticleList> */}
      </section>
    </main>
  );
};

export default Articles;
