import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ColorChooser,
  ImageLoader,
  MessageDisplay,
} from "../../components/common";
import { ProductShowcaseGrid } from "../../components/product";
import { RECOMMENDED_PRODUCTS, SHOP } from "../../constants/routes";
import { displayMoney } from "../../helpers/utils";
import {
  useBasket,
  useDocumentTitle,
  useProduct,
  useRecommendedProducts,
  useScrollTop,
} from "../../hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

const ViewProduct = () => {
  const { id } = useParams();
  const { product, isLoading, error } = useProduct(id);
  const { addToBasket, isItemOnBasket } = useBasket(id);
  useScrollTop();
  useDocumentTitle(`View ${product?.name || "Item"}`);

  const [selectedImage, setSelectedImage] = useState(product?.image || "");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

  const {
    recommendedProducts,
    fetchRecommendedProducts,
    isLoading: isLoadingFeatured,
    error: errorFeatured,
  } = useRecommendedProducts(6);
  const colorOverlay = useRef(null);

  useEffect(() => {
    setSelectedImage(product?.image);
    setSelectedColor("");
    setSelectedSize("");
    console.log(product);
  }, [product]);

  const onSelectedSizeChange = (newValue) => {
    setSelectedSize(newValue.value);
  };

  const onSelectedColorChange = (color) => {
    setSelectedColor(color);
    // if (colorOverlay.current) {
    //   colorOverlay.current.value = color.hex;
    // }
  };

  const hasColors = useMemo(() => {
    return product?.availableColors?.length > 0;
  }, [product]);

  const hasSizes = useMemo(() => {
    return !product?.sizes?.includes("Free Size");
  }, [product]);

  const complete = useMemo(() => {
    if (hasColors && !selectedColor) {
      return false;
    }
    if (hasSizes && !selectedSize) {
      return false;
    }
    return true;
  }, [product, selectedColor, selectedSize]);

  const numInStock = useMemo(() => {
    console.log("color: ", selectedColor, "size: ", selectedSize);

    if (hasSizes && hasColors && selectedColor && selectedSize) {
      const colorName = product.availableColors.filter(
        (color) => color.hex === selectedColor
      )[0].name;
      let stock = product.stock[selectedSize][colorName];
      return stock;
    }
    if (hasColors && selectedColor && !hasSizes) {
      const colorName = product.availableColors.filter(
        (color) => color.hex === selectedColor
      )[0].name;
      let stock = product.stock["Free Size"][colorName];
      return stock;
    }
    if (!hasColors && !hasSizes) {
      let stock = product?.stock?.["Free Size"]?.["Free Color"];
      return stock;
    }
    if (!hasColors && hasSizes) {
      let stock = product?.stock?.[selectedSize]?.["Free Color"];
      return stock;
    }

    return undefined;
  }, [selectedColor, selectedSize, complete]);

  useEffect(() => {
    console.log(numInStock);
  }, [numInStock]);

  const inStock = useMemo(() => {
    return numInStock > 0;
  }, [numInStock]);

  const handleAddToBasket = () => {
    addToBasket({
      ...product,
      selectedColor,
      selectedSize: selectedSize || product.sizes[0],
      id: product.id,
    });
  };

  return (
    <main className="content">
      {isLoading && (
        <div className="loader">
          <h4>Loading Product...</h4>
          <br />
          <LoadingOutlined style={{ fontSize: "3rem" }} />
        </div>
      )}
      {error && <MessageDisplay message={error} />}
      {product && !isLoading && (
        <div className="product-view">
          <Link to={SHOP}>
            <h3 className="button-link d-inline-flex">
              <ArrowLeftOutlined />
              &nbsp; Back to shop
            </h3>
          </Link>
          <div className="product-modal">
            {product.imageCollection.length !== 0 && (
              <div className="product-modal-image-collection">
                {product.imageCollection.map((image) => (
                  <div
                    className="product-modal-image-collection-wrapper"
                    key={image.id}
                    onClick={() => setSelectedImage(image.url)}
                    role="presentation"
                  >
                    <ImageLoader
                      className="product-modal-image-collection-img"
                      src={image.url}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="product-modal-image-wrapper">
              {/* {selectedColor && (
                <input
                  type="color"
                  disabled
                  ref={colorOverlay}
                  id="color-overlay"
                />
              )} */}
              <ImageLoader
                alt={product.name}
                className="product-modal-image"
                src={selectedImage}
              />
            </div>
            <div className="product-modal-details">
              <br />
              <span className="text-subtle">{product.brand}</span>
              <h1 className="margin-top-0">{product.name}</h1>
              <span>{product.description}</span>
              <br />
              <br />
              <div className="divider" />
              <br />
              <div>
                <span className="text-subtle">
                  Select Size{" "}
                  {inStock && (
                    <span className="text-subtle" style={{ float: "right" }}>
                      {numInStock} in stock
                    </span>
                  )}
                  {!inStock && numInStock !== undefined && (
                    <span className="text-subtle" style={{ float: "right" }}>
                      Out of stock
                    </span>
                  )}
                </span>
                <br />
                <Select
                  placeholder="--Select Size--"
                  onChange={onSelectedSizeChange}
                  options={product.sizes
                    .sort((a, b) => (a < b ? -1 : 1))
                    .map((size) => ({ label: `${size}`, value: size }))}
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 10 }) }}
                />
              </div>
              <br />
              {product.availableColors.length >= 1 && (
                <div>
                  <span className="text-subtle">Select Color</span>
                  <br />
                  <br />
                  <ColorChooser
                    availableColors={product.availableColors}
                    onSelectedColorChange={onSelectedColorChange}
                  />
                </div>
              )}
              <h1>{displayMoney(product.price)}</h1>
              <div className="product-modal-action">
                <button
                  disabled={!inStock || !complete}
                  className={`button button-small ${
                    isItemOnBasket(product.id)
                      ? "button-border button-border-gray"
                      : ""
                  }`}
                  onClick={handleAddToBasket}
                  type="button"
                >
                  {isItemOnBasket(product.id)
                    ? "Remove From Basket"
                    : "Add To Basket"}
                </button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "10rem" }}>
            <div className="display-header">
              <h1>Recommended</h1>
              <Link to={RECOMMENDED_PRODUCTS}>See All</Link>
            </div>
            {errorFeatured && !isLoadingFeatured ? (
              <MessageDisplay
                message={error}
                action={fetchRecommendedProducts}
                buttonLabel="Try Again"
              />
            ) : (
              <ProductShowcaseGrid
                products={recommendedProducts}
                skeletonCount={3}
              />
            )}
          </div>
        </div>
      )}
    </main>
  );
};

export default ViewProduct;
