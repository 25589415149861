import PropType from "prop-types";

const MenuToggle = ({ children }) => {
  const onClickToggle = () => {
    if (document.body.classList.contains("is-menu-open")) {
      document.body.classList.remove("is-menu-open");
    } else {
      document.body.classList.add("is-menu-open");
    }
  };

  document.addEventListener("click", (e) => {
    const closest = e.target.closest(".menu");
    const toggle = e.target.closest(".menu-toggle");
    const closeToggle = e.target.closest(".menu-item-remove");

    if (
      !closest &&
      document.body.classList.contains("is-menu-open") &&
      !toggle &&
      !closeToggle
    ) {
      document.body.classList.remove("is-menu-open");
    }
  });

  return children({ onClickToggle });
};

MenuToggle.propTypes = {
  children: PropType.oneOfType([
    PropType.arrayOf(PropType.node),
    PropType.func,
    PropType.node,
  ]).isRequired,
};

export default MenuToggle;
