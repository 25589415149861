export const HOME = "/";
export const SHOP = "/shop";
export const FEATURED_PRODUCTS = "/featured";
export const RECOMMENDED_PRODUCTS = "/recommended";
export const ACCOUNT = "/account";
export const ACCOUNT_EDIT = "/account/edit";
export const ADMIN_DASHBOARD = "/admin/dashboard";
export const ADMIN_PRODUCTS = "/admin/products";
export const ADMIN_ORDERS = "/admin/orders";
export const ADMIN_USERS = "/admin/users";
export const ADD_PRODUCT = "/admin/add";
export const ADD_ARTICLE = "/admin/new-article";
export const EDIT_ARTICLE = "/admin/article/edit";
export const ADMIN_ARTICLE = "/admin/article/:id";
export const ADMIN_ARTICLES = "/admin/articles";
export const EDIT_PRODUCT = "/admin/edit";
export const SEARCH = "/search/:searchKey";
export const SIGNIN = "/signin";
export const SIGNOUT = "/signout";
export const SIGNUP = "/signup";
export const FORGOT_PASSWORD = "/forgot_password";
export const CHECKOUT_STEP_1 = "/checkout/step1";
export const CHECKOUT_STEP_2 = "/checkout/step2";
export const CHECKOUT_STEP_3 = "/checkout/step3";
export const VIEW_PRODUCT = "/product/:id";
export const TERMS_OF_SERVICE = "/terms-of-service";
export const PRIVACY_POLICY = "/privacy-policy";
export const DATA_DELETION_POLICY = "/data-deletion-policy";
export const PAYMENT_COMPLETE = "/payment-complete";
export const ARTICLES = "/articles";
export const VIEW_ARTICLE = "/article/:id";
