/* eslint-disable max-len */
import * as ROUTE from "../../constants/routes";
import { BasketItem, BasketToggle, onClose } from "../../components/basket";
import { Boundary, Modal } from "../../components/common";
import { CHECKOUT_STEP_1 } from "../../constants/routes";
import firebase from "firebase/compat/app";
import { calculateTotal, displayMoney } from "../../helpers/utils";
import { useDidMount, useModal } from "../../hooks";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearBasket } from "../../redux/actions/basketActions";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../Context";

const Basket = () => {
  const { isOpenModal, onOpenModal, onCloseModal } = useModal();
  const { basket, user } = useSelector((state) => ({
    basket: state.basket,
    user: state.auth,
  }));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const didMount = useDidMount();

  const { isMobileNavOpen, setMobileNavOpen } = useContext(Context);

  useEffect(() => {
    if (didMount && firebase.auth.currentUser && basket.length !== 0) {
      firebase
        .saveBasketItems(basket, firebase.auth.currentUser.uid)
        .then(() => {
          console.log("Item saved to basket");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [basket.length]);

  const onCheckOut = () => {
    if (basket.length !== 0 && user) {
      document.body.classList.remove("is-basket-open");
      navigate(CHECKOUT_STEP_1);
    } else {
      onOpenModal();
    }
  };

  const onSignInClick = () => {
    onCloseModal();
    document.body.classList.remove("basket-open");
    navigate(CHECKOUT_STEP_1);
  };

  const onClearBasket = () => {
    if (basket.length !== 0) {
      dispatch(clearBasket());
    }
  };

  return user && user.role === "ADMIN" && pathname.includes("/admin") ? null : (
    <Boundary>
      <Modal isOpen={isOpenModal} onRequestClose={onCloseModal}>
        <p className="text-center">You must sign in to continue checking out</p>
        <br />
        <div className="d-flex-center">
          <button
            className="button button-border button-border-gray button-small"
            onClick={onCloseModal}
            type="button"
          >
            Continue shopping
          </button>
          &nbsp;
          <button
            className="button button-small"
            onClick={onSignInClick}
            type="button"
          >
            Sign in to checkout
          </button>
        </div>
      </Modal>
      {!isMobileNavOpen ? (
        <div className="basket">
          <div className="basket-list">
            <div className="basket-header">
              <h3 className="basket-header-title">
                My Basket &nbsp;
                <span>({` ${basket.length} `})</span>
              </h3>
              <BasketToggle>
                {({ onClickToggle }) => (
                  <span
                    className="basket-toggle button button-border button-border-gray button-small"
                    onClick={onClickToggle}
                    role="presentation"
                  >
                    Close
                  </span>
                )}
              </BasketToggle>
              <button
                className="basket-clear button button-border button-border-gray button-small"
                disabled={basket.length === 0}
                onClick={onClearBasket}
                type="button"
              >
                <span>Clear Basket</span>
              </button>
            </div>
            {basket.length <= 0 && (
              <div className="basket-empty">
                <h5 className="basket-empty-msg">Your basket is empty</h5>
              </div>
            )}
            {basket.map((product, i) => (
              <BasketItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${product.id}_${i}`}
                product={product}
                basket={basket}
                dispatch={dispatch}
              />
            ))}
            <div className="basket-checkout" style={{ bottom: "5vh" }}>
              <div className="basket-total">
                <p className="basket-total-title">Subtotal Amout:</p>
                <h2 className="basket-total-amount">
                  {displayMoney(
                    calculateTotal(
                      basket.map((product) => product.price * product.quantity)
                    )
                  )}
                </h2>
              </div>
              <button
                className="basket-checkout-button button"
                disabled={basket.length === 0 || pathname === "/checkout"}
                onClick={onCheckOut}
                type="button"
              >
                Check Out
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="basket">
          <div className="basket-list">
            <div className="basket-header">
              <h3 className="basket-header-title">Jiva's Originals</h3>
              <BasketToggle>
                {({ onClose }) => (
                  <span
                    className="basket-toggle button button-border button-border-gray button-small"
                    onClick={onClose}
                    role="presentation"
                  >
                    Close
                  </span>
                )}
              </BasketToggle>
            </div>
            <ul
              className="navigation-menu-main menu"
              // hacking with the non-portrait class
              style={{
                margin: "0 auto",
                right: "25vw",
                position: "relative",
                float: "right",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <li onClick={onClose}>
                <NavLink
                  activeClassName="navigation-menu-active"
                  exact
                  to={ROUTE.HOME}
                >
                  <h2>Home</h2>
                  <div className="divider-black" />
                </NavLink>
              </li>
              <li onClick={onClose}>
                <NavLink
                  activeClassName="navigation-menu-active"
                  to={ROUTE.SHOP}
                >
                  <h2>Shop</h2>
                  <div className="divider-black" />
                </NavLink>
              </li>
              <li onClick={onClose}>
                <NavLink
                  activeClassName="navigation-menu-active"
                  to={ROUTE.FEATURED_PRODUCTS}
                >
                  <h2>Featured</h2>
                  <div className="divider-black" />
                </NavLink>
              </li>
              <li onClick={onClose}>
                <NavLink
                  activeClassName="navigation-menu-active"
                  to={ROUTE.RECOMMENDED_PRODUCTS}
                >
                  <h2>Recommended</h2>
                  <div className="divider-black" />
                </NavLink>
              </li>
            </ul>
            <div className="basket-checkout" style={{ bottom: "5vh" }}>
              <div className="basket-total">
                <p className="basket-total-title">Subtotal Amout:</p>
                <h2 className="basket-total-amount">
                  {displayMoney(
                    calculateTotal(
                      basket.map((product) => product.price * product.quantity)
                    )
                  )}
                </h2>
              </div>
              <button
                className="basket-checkout-button button"
                disabled={basket.length === 0 || pathname === "/checkout"}
                onClick={onCheckOut}
                type="button"
              >
                Check Out
              </button>
            </div>
          </div>
        </div>
      )}
    </Boundary>
  );
};

export default Basket;
