/* eslint-disable react/jsx-props-no-spreading */
import ArticleList from "./ArticleList";
import ArticleGrid from "./ArticleGrid";
import { useDocumentTitle, useScrollTop } from "../../../../hooks";
import React, { useContext, useEffect, useRef } from "react";
import { Context } from "../../../../Context";
import Article from "../../view";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import { ADD_ARTICLE } from "../../../../constants/routes";

const AdminArticles = () => {
  useDocumentTitle("Articles | Jiva's Originals");
  useScrollTop();

  const { articles, getArticles } = useContext(Context);

  const navigate = useNavigate();
  const articleRef = useRef(null);

  // const onClickEdit = () => {
  //   navigate(`${}/${article.id}`);
  // };

  const onDeleteProduct = () => {
    articleRef.current.classList.toggle("item-active");
  };

  const onConfirmDelete = () => {
    articleRef.current.classList.remove("item-active");
  };

  const onCancelDelete = () => {
    articleRef.current.classList.remove("item-active");
  };

  useEffect(() => {
    if (!articles) {
      getArticles();
    }
  }, [articles]);

  return (
    <main className="content">
      <section className="article-list-wrapper">
        {/* <ArticleList {...articles}> */}
        {/* <ArticleGrid articles={articles} /> */}
        <Link to={ADD_ARTICLE}>
          <Button
            variant="contained"
            style={{
              fontSize: "large",
              margin: "0",
              position: "relative",
              left: "3.5rem",
            }}
          >
            + New Article
          </Button>
        </Link>
        {articles?.map((article) => {
          return (
            <div>
              <Article propArticle={article} />;
              <div className="item-action" ref={articleRef}>
                {/* <button
                    className="button button-border button-small"
                    onClick={onClickEdit}
                    type="button"
                  >
                    Edit
                  </button> */}
                &nbsp;
                <button
                  className="button button-border button-small button-danger"
                  onClick={onDeleteProduct}
                  type="button"
                >
                  Delete
                </button>
                <div className="item-action-confirm">
                  <h5>Are you sure you want to delete this?</h5>
                  <button
                    className="button button-small button-border"
                    onClick={onCancelDelete}
                    type="button"
                  >
                    No
                  </button>
                  &nbsp;
                  <button
                    className="button button-small button-danger"
                    onClick={onConfirmDelete}
                    type="button"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        {/* </ArticleList> */}
      </section>
    </main>
  );
};

export default AdminArticles;
