import { CheckOutlined } from "@ant-design/icons";
import { ImageLoader } from "../../../../components/common";
import PropType from "prop-types";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const ArticleItem = ({ article }) => {
  const navigate = useNavigate();

  const onClickItem = () => {
    if (!article) return;

    if (article.id) {
      navigate(`/article/${article.id}`);
    }
  };

  return (
    <SkeletonTheme color="#e1e1e1" highlightColor="#f2f2f2">
      <div
        className={`article-card ${!article.id ? "article-loading" : ""}`}
        style={{
          border: article ? "1px solid #a6a5a5" : "",
          boxShadow: article ? "0 10px 15px rgba(0, 0, 0, .07)" : "none",
        }}
      >
        <div
          className="article-card-content"
          onClick={onClickItem}
          role="presentation"
        >
          <div className="article-card-img-wrapper">
            {article?.contents[0] ? (
              <ImageLoader
                alt={article.name}
                className="article-card-img"
                src={article?.contents[0]}
              />
            ) : (
              <Skeleton width="100%" height="90%" />
            )}
          </div>
          <div className="article-details">
            <h5 className="article-card-name text-overflow-ellipsis margin-auto">
              {article?.name || <Skeleton width={80} />}
            </h5>
            <p className="article-card-brand">
              {article?.text?.substring(0, 100) + "..." || (
                <Skeleton width={60} />
              )}
            </p>
          </div>
        </div>
        {article?.id && (
          <button
            className={`article-card-button button-small button button-block`}
            onClick={onClickItem}
            type="button"
          >
            Read Article
          </button>
        )}
      </div>
    </SkeletonTheme>
  );
};

ArticleItem.defaultProps = {
  isItemOnBasket: undefined,
  addToBasket: undefined,
};

ArticleItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  article: PropType.object.isRequired,
  isItemOnBasket: PropType.func,
  addToBasket: PropType.func,
};

export default ArticleItem;
